import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import React, { useCallback, useEffect } from 'react';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { useSuppliers } from '../common/hooks/retrieve-data/suppliers';
import { useAppDispatch } from '../../application/configuration/setup/hooks';
import { commonActions } from '../../application/redux/common.slice';
import { FormattedMessageUpperFirst } from '../../application/i18n/FormattedMessageUpperFirst';
import Select, { type SelectOption } from '@rio-cloud/rio-uikit/Select';
import { useManageSuppliersEnabledRaw, useSupplierIdRaw } from '../../application/hooks/selectors';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { LoadingLocation } from '../../domain/model/location/Location.types';
import { useNavigate } from 'react-router-dom';
import { toBookingView, toSequenceFlowAdministrationView } from '../../application/routing/routes';
import { useFormatMessageWithUpperFirst } from '../../application/i18n/useFormatMessage';
import { LocationTagList } from './LocationTagList';
import { useSortedAndSelectedLoadingLocations } from './hooks/retrieve-data/useSortedAndSelectedLoadingLocations';

type LoadingLocationSelectionProps = {
    loadingLocations: LoadingLocation[] | undefined;
    loadingLocation: LoadingLocation | undefined;
    onSelectLoadingLocation: (location: LoadingLocation) => void;
};

const LocationSelection = ({
    onSelectLoadingLocation,
    loadingLocations,
    loadingLocation,
}: LoadingLocationSelectionProps) => {
    if (!loadingLocations || loadingLocations?.length <= 1) {
        return null;
    }

    return (
        <>
            <div className="border border-width-1 border-left-none border-right-none bg-lightest padding-10 text-color-dark">
                <FormattedMessageUpperFirst id="scm-web.domain.loadingLocationSelection" />
            </div>
            <LocationTagList
                loadingLocations={loadingLocations}
                selectedLoadingLocation={loadingLocation}
                selectLoadingLocation={onSelectLoadingLocation}
            />
        </>
    );
};

type SupplierSelectionProps = {
    disabled: boolean;
    suppliers: string[] | undefined;
    supplierId: string | undefined;
    onSelectSupplier: (supplierId: string) => void;
};

const SupplierSelection = ({ suppliers, disabled, supplierId, onSelectSupplier }: SupplierSelectionProps) => {
    const options: SelectOption[] =
        suppliers?.map((supplier) => ({
            id: supplier,
            label: supplier,
        })) ?? [];

    return (
        <div className="margin-10">
            <label htmlFor="supplier-select">
                <FormattedMessageUpperFirst id="scm-web.description.supplierId" />
            </label>

            <Select
                id="supplier-select"
                placeholder={<FormattedMessageUpperFirst id="scm-web.callToAction.pleaseSelect" />}
                value={supplierId ? [supplierId] : undefined}
                disabled={disabled}
                options={options}
                onChange={(selectedSupplier) => {
                    onSelectSupplier(selectedSupplier!.id);
                }}
                useFilter
            />
        </div>
    );
};

type ManageSupplierTitleProps = {
    toggleValue: boolean;
    onToggle: (newValue: boolean) => void;
};

const ManageSupplierTitle = ({ toggleValue, onToggle }: ManageSupplierTitleProps) => {
    const formatMessage = useFormatMessageWithUpperFirst();

    return (
        <div className="display-flex justify-content-between align-items-center">
            <FormattedMessageUpperFirst
                className="text-size-16"
                id="scm-web.domain.supplierManagement.manageSupplier"
            />
            <Switch
                checked={toggleValue}
                onChange={onToggle}
                enabledText={formatMessage('scm-web.action.on')}
                disabledText={formatMessage('scm-web.action.off')}
            />
        </div>
    );
};

const useSupplierManagementState = () => {
    const manageSuppliers = useManageSuppliersEnabledRaw();
    const supplierId = useSupplierIdRaw();
    const dispatch = useAppDispatch();

    const setManageSuppliers = useCallback((enabled: boolean) => {
        dispatch(commonActions.manageSuppliersEnabled(enabled));
    }, []);

    useEffect(() => {
        if (supplierId) {
            setManageSuppliers(true);
        }
    }, [supplierId]);

    return { supplierId, manageSuppliers, setManageSuppliers };
};

const SupplierManagement = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { supplierId, manageSuppliers, setManageSuppliers } = useSupplierManagementState();

    const {
        currentData: sortedLoadingLocations,
        isError: isLocationsError,
        selectedLoadingLocation,
        selectLoadingLocation,
    } = useSortedAndSelectedLoadingLocations();

    const { data: suppliers, isError: isSuppliersError } = useSuppliers();

    useEffect(() => {
        if (isSuppliersError || isLocationsError || (!!sortedLoadingLocations && sortedLoadingLocations.length === 0)) {
            Notification.error(<FormattedMessageUpperFirst id="scm-web.notification.somethingWentWrongTryAgain" />);
        }
    }, [isSuppliersError, isLocationsError, sortedLoadingLocations]);

    if (!suppliers || suppliers.length === 0) {
        return null;
    }

    const handleManageSuppliersToggle = (newManageSuppliers: boolean) => {
        setManageSuppliers(newManageSuppliers);
        if (newManageSuppliers) {
            dispatch(commonActions.supplierIdSelected(suppliers[0]));
            navigate(toBookingView(suppliers[0]));
        } else {
            dispatch(commonActions.leftSupplierContext());
            navigate(toSequenceFlowAdministrationView());
        }
    };

    const handleSupplierSelect = (newSupplierId: string) => {
        dispatch(commonActions.supplierIdSelected(newSupplierId));
        navigate(toBookingView(newSupplierId));
    };

    return (
        <ActionBarItem hidePopoverOnClick={false}>
            <ActionBarItem.Icon id="supplier-management-button">
                <span className="icon rioglyph rioglyph-factory" />
            </ActionBarItem.Icon>

            <ActionBarItem.Popover
                title={<ManageSupplierTitle toggleValue={manageSuppliers} onToggle={handleManageSuppliersToggle} />}
                className="padding-0"
            >
                <SupplierSelection
                    supplierId={supplierId}
                    suppliers={suppliers}
                    disabled={!manageSuppliers}
                    onSelectSupplier={handleSupplierSelect}
                />

                {manageSuppliers && (
                    <LocationSelection
                        loadingLocation={selectedLoadingLocation}
                        loadingLocations={sortedLoadingLocations}
                        onSelectLoadingLocation={selectLoadingLocation}
                    />
                )}
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default SupplierManagement;

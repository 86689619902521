import { generatePath } from 'react-router-dom';

export const routes = {
    DEFAULT: '/',
    ERROR: '/error',

    // supplier pages
    SUPPLIER_BASE: '/supplier/:supplierId',
    SUPPLIER_BOOKINGS: '/supplier/:supplierId/bookings',
    SUPPLIER_BATCH_FLOW_TRANSPORT_DETAILS: '/supplier/:supplierId/bookings/batch-flow-details',
    SUPPLIER_SEQUENCE_FLOW_TRANSPORT_DETAILS: '/supplier/:supplierId/bookings/sequence-flow-details',
    SUPPLIER_TRANSPORTS: '/supplier/:supplierId/transports',
    SUPPLIER_CLOSING_DAY_ADMINISTRATION: '/supplier/:supplierId/administration/closing-days',
    SUPPLIER_DEFAULT_FEATURE: 'bookings',

    // material planner pages
    MATERIAL_PLANNER_BASE: '/administration',
    MATERIAL_PLANNER_SEQUENCE_FLOW_ADMINISTRATION: '/administration/sequence-flows',
    MATERIAL_PLANNER_CLOSING_DAY_ADMINISTRATION: '/administration/closing-days',
    MATERIAL_PLANNER_DEFAULT_FEATURE: 'sequence-flows',
};

export const toBookingView = (supplierId: string) => generatePath(routes.SUPPLIER_BOOKINGS, { supplierId });

export const toSequenceFlowAdministrationView = () =>
    generatePath(routes.MATERIAL_PLANNER_SEQUENCE_FLOW_ADMINISTRATION);

export const toTransportView = (supplierId: string) => generatePath(routes.SUPPLIER_TRANSPORTS, { supplierId });

export const toSupplierClosingDayView = (supplierId: string) =>
    generatePath(routes.SUPPLIER_CLOSING_DAY_ADMINISTRATION, { supplierId });

export const toBatchFlowBookingDetailsView = (
    supplierId: string,
    shipToId: string,
    pickupTime: string,
    cutoffTimeStartAt: string | undefined = undefined,
    cutoffTimeEndAt: string | undefined = undefined
) => {
    const encodedId = encodeURIComponent(shipToId);
    const encodedPickupTime = encodeURIComponent(pickupTime);
    const cutoffTimeStartAtPathParam = cutoffTimeStartAt
        ? `&cutoffTimeStartAt=${encodeURIComponent(cutoffTimeStartAt)}`
        : '';
    const cutoffTimeEndAtPathParam = cutoffTimeEndAt ? `&cutoffTimeEndAt=${encodeURIComponent(cutoffTimeEndAt)}` : '';

    return (
        `${toBookingView(supplierId)}/batch-flow-details?shipToId=${encodedId}` +
        `&pickupTime=${encodedPickupTime}` +
        cutoffTimeStartAtPathParam +
        cutoffTimeEndAtPathParam
    );
};

export const toSequenceFlowBookingDetailsView = (
    supplierId: string,
    transportDemandBundleId: string,
    cutoffTimeStartAt: string | undefined = undefined,
    cutoffTimeEndAt: string | undefined = undefined
) => {
    const encodedId = encodeURIComponent(transportDemandBundleId);
    const cutoffTimeStartAtPathParam = cutoffTimeStartAt
        ? `&cutoffTimeStartAt=${encodeURIComponent(cutoffTimeStartAt)}`
        : '';
    const cutoffTimeEndAtPathParam = cutoffTimeEndAt ? `&cutoffTimeEndAt=${encodeURIComponent(cutoffTimeEndAt)}` : '';

    return (
        `${toBookingView(supplierId)}/sequence-flow-details?transportDemandBundleId=${encodedId}` +
        cutoffTimeStartAtPathParam +
        cutoffTimeEndAtPathParam
    );
};

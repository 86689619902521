import { getSelectedLoadingLocation, getSupplierId } from '../redux/common.selectors';
import { useAppSelector } from '../configuration/setup/hooks';
import { LoadingLocation } from '../../domain/model/location/Location.types';
import { UserRole } from '../configuration/login/login.slice';
import { getUserRole } from '../configuration/login/login.selectors';

export const useSupplierIdRaw: () => string | undefined = () => useAppSelector(getSupplierId);

export const useSelectedLoadingLocationRaw: () => LoadingLocation | undefined = () =>
    useAppSelector(getSelectedLoadingLocation);

export const useManageSuppliersEnabledRaw = (): boolean => {
    return useAppSelector((state) => state.common.manageSuppliersEnabled);
};

export const useUserRole = (): UserRole => {
    const userRole = useAppSelector(getUserRole);
    if (!userRole) {
        throw new Error(
            'Tried to render component without a user role. Maybe there is an issue in our routing config.'
        );
    }
    return userRole;
};

const supplierContextRoles: UserRole[] = ['supplier', 'material-planner', 'admin'];

export const isInSupplierContext = (userRole: UserRole, supplierId: string | undefined): supplierId is string =>
    supplierContextRoles.includes(userRole) && !!supplierId;

const materialPlannerContextRoles: UserRole[] = ['material-planner', 'admin'];

export const isInMaterialPlannerContext = (
    userRole: UserRole,
    supplierId: string | undefined
): supplierId is undefined => materialPlannerContextRoles.includes(userRole) && !supplierId;

import { useCallback, useEffect, useMemo } from 'react';
import type { LoadingLocation } from '../../../../domain/model/location/Location.types';
import { getLoadingLocationLabel } from '../../../../domain/model/location/loadingLocationFormatter';
import { useAllLocations } from '../../../common/hooks/retrieve-data/locations';
import { useAppDispatch } from '../../../../application/configuration/setup/hooks';
import { useSelectedLoadingLocationRaw } from '../../../../application/hooks/selectors';
import { commonActions } from '../../../../application/redux/common.slice';

const byLabel = (a: LoadingLocation, b: LoadingLocation) =>
    getLoadingLocationLabel(a).localeCompare(getLoadingLocationLabel(b));

type UseAllLocationsResponse = ReturnType<typeof useAllLocations>;

type UseSortedLoadingLocationsResponse = Omit<UseAllLocationsResponse, 'currentData' | 'data'> & {
    currentData: LoadingLocation[] | undefined;
};

const useSortedLoadingLocations = (): UseSortedLoadingLocationsResponse => {
    const response = useAllLocations();

    const sortedLoadingLocations = useMemo(
        () => response.currentData?.loadingLocations?.toSorted(byLabel),
        [response.currentData]
    );

    return { ...response, currentData: sortedLoadingLocations };
};

export const useSortedAndSelectedLoadingLocations = () => {
    const dispatch = useAppDispatch();
    const selectedLoadingLocation = useSelectedLoadingLocationRaw();
    const response = useSortedLoadingLocations();

    const selectLoadingLocation = useCallback((location: LoadingLocation) => {
        dispatch(commonActions.loadingLocationSelected(location));
    }, []);

    useEffect(() => {
        if (!response.currentData || response.currentData.length === 0) {
            // we don't clear the selected loading location here as the loading locations might be coming in with a
            // subsequent re-render; clearing the selected loading location is done when switching the supplier ID
            return;
        }

        if (!selectedLoadingLocation || !response.currentData.includes(selectedLoadingLocation)) {
            selectLoadingLocation(response.currentData[0]);
        }
    }, [response.currentData, selectedLoadingLocation]);

    return { ...response, selectedLoadingLocation, selectLoadingLocation };
};

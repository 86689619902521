import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserProfile {
    sub?: string;
    azp?: string;
    account?: string;
    givenName?: string;
    familyName?: string;
    name?: string;
    locale?: string;
    email?: string;
}

export type UserRole = 'admin' | 'supplier' | 'material-planner';

export interface LoginState {
    userProfile: UserProfile | undefined;
    userSessionExpired: boolean;
    hasUserSessionEverExpired: boolean;
    userRole: UserRole | undefined;
}

const initialState: LoginState = {
    userProfile: undefined,
    userSessionExpired: false,
    hasUserSessionEverExpired: false,
    userRole: undefined,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userProfileObtained: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload;
        },
        userRoleObtained: (state, action: PayloadAction<UserRole>) => {
            state.userRole = action.payload;
        },
        userSessionExpired: (state) => {
            state.userSessionExpired = true;
            state.hasUserSessionEverExpired = true;
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false;
        },
    },
});

export const { userProfileObtained, userRoleObtained, userSessionExpired, userSessionRenewed } = loginSlice.actions;

export default loginSlice.reducer;

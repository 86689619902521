import scmServiceApi from '../scmService.api';
import { SupplierResponseSchema } from './supplier.schema';

export const supplierEndpoints = scmServiceApi.injectEndpoints({
    endpoints: (builder) => ({
        getSuppliers: builder.query<string[], void>({
            query: () => ({
                url: '/suppliers',
            }),
            transformResponse: (x: unknown) => {
                const supplierResponse = SupplierResponseSchema.parse(x);
                return supplierResponse.items;
            },
        }),
    }),
});

export const { useGetSuppliersQuery } = supplierEndpoints;

import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import AppLayout from '../../features/layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';
import { routes } from './routes';
import { RoutingErrorElement } from '../error/RoutingErrorElement';
import ErrorPage from '../../features/ErrorPage';

// Lazy load pages for better performance and automatically split the bundle accordingly
const DefaultPage = suspendPageLoad(() => import('../../features/DefaultPage'));

const SupplierPage = suspendPageLoad(() => import('./SupplierPage'));
const BookingPage = suspendPageLoad(() => import('../../features/booking/BookingPage'));
const BookingView = suspendPageLoad(() => import('../../features/booking/booking-view/BookingView'));
const BatchFlowBookingDetailsView = suspendPageLoad(
    () => import('../../features/booking/batch-flow-booking-details-view/BatchFlowBookingDetailsView')
);
const SequenceFlowBookingDetailsView = suspendPageLoad(
    () => import('../../features/booking/sequence-flow-booking-details-view/SequenceFlowBookingDetailsView')
);
const TransportView = suspendPageLoad(() => import('../../features/transport/transport-view/TransportView'));
const SupplierClosingDayView = suspendPageLoad(
    () => import('../../features/closing-day/supplier-closing-day-view/SupplierClosingDayView')
);

const MaterialPlannerPage = suspendPageLoad(() => import('./MaterialPlannerPage'));
const MaterialPlannerClosingDayPage = suspendPageLoad(
    () => import('../../features/closing-day/MaterialPlannerClosingDayPage')
);
const SequenceFlowView = suspendPageLoad(
    () => import('../../features/sequence-flow/sequence-flow-view/SequenceFlowView')
);

export const router = createHashRouter(
    createRoutesFromElements(
        <Route element={<AppLayout />} errorElement={<RoutingErrorElement />}>
            <Route path={routes.DEFAULT} element={<DefaultPage />} />

            <Route path={routes.ERROR} element={<ErrorPage />} />

            <Route path={routes.SUPPLIER_BASE} element={<SupplierPage />}>
                <Route path={routes.SUPPLIER_BOOKINGS} element={<BookingPage />}>
                    <Route index element={<BookingView />} />

                    <Route
                        path={routes.SUPPLIER_BATCH_FLOW_TRANSPORT_DETAILS}
                        element={<BatchFlowBookingDetailsView />}
                    />

                    <Route
                        path={routes.SUPPLIER_SEQUENCE_FLOW_TRANSPORT_DETAILS}
                        element={<SequenceFlowBookingDetailsView />}
                    />
                </Route>

                <Route path={routes.SUPPLIER_TRANSPORTS} index element={<TransportView />} />

                <Route path={routes.SUPPLIER_CLOSING_DAY_ADMINISTRATION} element={<SupplierClosingDayView />} />
            </Route>

            <Route path={routes.MATERIAL_PLANNER_BASE} element={<MaterialPlannerPage />}>
                <Route path={routes.MATERIAL_PLANNER_SEQUENCE_FLOW_ADMINISTRATION} element={<SequenceFlowView />} />

                <Route
                    path={routes.MATERIAL_PLANNER_CLOSING_DAY_ADMINISTRATION}
                    element={<MaterialPlannerClosingDayPage />}
                />
            </Route>

            <Route path="*" element={<Navigate to={routes.DEFAULT} />} />
        </Route>
    )
);

import React, { useEffect } from 'react';
import { LoadingLocation } from '../../domain/model/location/Location.types';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { LocationTagList } from './LocationTagList';
import { useSortedAndSelectedLoadingLocations } from './hooks/retrieve-data/useSortedAndSelectedLoadingLocations';
import { FormattedMessageUpperFirst } from '../../application/i18n/FormattedMessageUpperFirst';

const LoadingLocationManagement = () => {
    const {
        currentData: sortedLoadingLocations,
        isError,
        selectedLoadingLocation,
        selectLoadingLocation,
    } = useSortedAndSelectedLoadingLocations();

    useEffect(() => {
        if (isError || (sortedLoadingLocations && sortedLoadingLocations.length === 0)) {
            Notification.error(<FormattedMessageUpperFirst id="scm-web.notification.somethingWentWrongTryAgain" />);
        }
    }, [sortedLoadingLocations, selectedLoadingLocation, isError]);

    if (!sortedLoadingLocations || sortedLoadingLocations?.length <= 1) {
        return null;
    }

    return (
        <ActionBarItem>
            <ActionBarItem.Icon id="site-selection-button">
                <span className="icon rioglyph rioglyph-factory"> </span>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover
                title={<FormattedMessage id="scm-web.domain.loadingLocationSelection" />}
                className="padding-0"
            >
                <LocationTagList
                    loadingLocations={sortedLoadingLocations}
                    selectedLoadingLocation={selectedLoadingLocation}
                    selectLoadingLocation={(location: LoadingLocation) => selectLoadingLocation(location)}
                />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default LoadingLocationManagement;

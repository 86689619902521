const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        authenticationBackend: 'production' | 'local' | 'msw';
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
        // only relevant for 'local' / 'msw' authentication backend
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        mockAccountId: string | undefined;
    };
}

export const config: ConfigState = {
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
        oauthScope: ['openid', 'profile', 'email'],
        authenticationBackend: import.meta.env.PROD
            ? 'production'
            : import.meta.env.MODE === 'local-BE'
              ? 'local'
              : 'msw',
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        // only relevant for 'local' / 'msw' authentication backend
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        mockAccountId: import.meta.env.VITE_VALID_RIO_ACCOUNT_ID,
    },
};

import { IntlFormatters } from '@formatjs/intl/src/types';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { TranslationKeys } from './translationKeys.types';
import upperFirst from 'lodash/upperFirst';
import { CountryCode } from '../../domain/model/location/Location.types';

type OriginalFormatMessage = IntlFormatters<string>['formatMessage'];
type Params = Parameters<OriginalFormatMessage>;

/**
 * Hook providing a convenience wrapper around `intl.formatMessage`.
 *
 * Instead of having to provide an object with `id` in it, you simply use the id directly.
 */
const useFormatMessage = () => {
    const intl = useIntl();

    return useCallback(
        (id: TranslationKeys, values?: Params[1], opts?: Params[2]) =>
            intl.formatMessage({ id }, values, opts) as string,
        [intl]
    );
};

export const useFormatMessageWithUpperFirst = () => {
    const formatMessage = useFormatMessage();

    return useCallback(
        (id: TranslationKeys, values?: Params[1], opts?: Params[2]) => upperFirst(formatMessage(id, values, opts)),
        [formatMessage]
    );
};

export const useFormatMessageForCountryCode = () => {
    const formatMessage = useFormatMessageWithUpperFirst();

    return useCallback(
        (countryCode: CountryCode) => {
            const countryCodeTranslationKey = countryCodeTranslations[countryCode];
            return countryCodeTranslationKey !== undefined ? formatMessage(countryCodeTranslationKey) : countryCode;
        },
        [formatMessage]
    );
};

const countryCodeTranslations: Record<CountryCode, TranslationKeys> = {
    CN: 'scm-web.domain.china',
    DE: 'scm-web.domain.germany',
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingLocation } from '../../domain/model/location/Location.types';

export type CommonState = {
    supplierId: string | undefined;
    selectedLoadingLocation: LoadingLocation | undefined;
    manageSuppliersEnabled: boolean;
};

const initialState: CommonState = {
    supplierId: undefined,
    selectedLoadingLocation: undefined,
    manageSuppliersEnabled: false,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        supplierIdSelected: (state, { payload }: PayloadAction<string>) => {
            if (state.supplierId !== payload) {
                state.supplierId = payload;
                state.selectedLoadingLocation = undefined;
            }
        },
        loadingLocationSelected: (state, { payload }: PayloadAction<LoadingLocation>) => {
            state.selectedLoadingLocation = payload;
        },
        manageSuppliersEnabled: (state, { payload }: PayloadAction<boolean>) => {
            state.manageSuppliersEnabled = payload;
        },
        leftSupplierContext: (state) => {
            state.supplierId = undefined;
            state.selectedLoadingLocation = undefined;
            state.manageSuppliersEnabled = false;
        },
    },
});

export const commonActions = commonSlice.actions;

export default commonSlice;

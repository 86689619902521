import { SessionRenewedResult } from './oauth.types';
import { UserRole } from './login.slice';

const isValidUserRole = (input: string | null): input is UserRole =>
    /^(?:admin|supplier|material-planner)$/.test(input!);

export const parseRoleFromSessionRenewedResult = (_result: SessionRenewedResult): UserRole => {
    /*
    NOTE: This is just a temporary solution introduced with TRA-2121 so that we simply parse the role from the URL until
    we get the role information _somehow_ in the SessionRenewedResult, e.g. as part of the UserProfile that contains the
    claims or something like that.

    Once we're receiving the roles from the SessionRenewedResult:
    - rename the function parameter to "result" (remove the underscore)
    - do the actual parsing here & write a few tests
    - get rid of all the URL magicks
    - remove isValidUserRole (it's deliberately here, not exported, and using a RegExp, as it's meant to be temporary)
     */
    const { search } = location; // '' or '?blah=fasel&role=supplier&schnarch=blub'
    const params = new URLSearchParams(search);
    const roleFromUrl = params.get('role');
    const role = isValidUserRole(roleFromUrl) ? roleFromUrl : 'supplier';

    console.debug(
        `%c[MOCK] setting user role to ${role} (role from URL: ${roleFromUrl})`,
        'color: white; background-color: hotpink; font-style: bold; padding: 2px; display: inline-block'
    );

    return role;
};

export const parseSupplierIdFromRenewedResult = (_result: SessionRenewedResult): string => {
    /*
    NOTE: This is just a temporary solution introduced with TRA-2122 so that we have the option to parse the supplierID
    from the URL until we get the supplier's ID _somehow_ from the SessionRenewedResult, e.g. as part of the UserProfile
    that contains the claims or something like that.

    Once we're receiving the roles from the SessionRenewedResult:
    - rename the function parameter to "result" (remove the underscore)
    - do the actual parsing here & write a few tests
    - get rid of all the URL magicks
     */
    const { hash } = location;

    const supplierIdFromHash = /#\/supplier\/([^\/]+)/.exec(hash)?.[1];
    const supplierId = supplierIdFromHash ?? '4711';

    console.debug(
        `%c[MOCK] setting supplier ID to ${supplierId} (from URL: ${supplierIdFromHash})`,
        'color: white; background-color: hotpink; font-style: bold; padding: 2px; display: inline-block'
    );

    return supplierId;
};

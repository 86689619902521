import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import loginReducer from '../configuration/login/login.slice';
import langReducer from '../i18n/lang.slice';
import appReducer from './app/app.slice';
import bookingSlice from '../../features/booking/redux/booking.slice';
import commonSlice from './common.slice';
import scmServiceApi from '../../features/api/scmService.api';
import transportSlice from '../../features/transport/redux/transportSlice';

export const getStore = () =>
    configureStore({
        reducer: {
            lang: langReducer,
            app: appReducer,
            login: loginReducer,

            // RTK slices
            [commonSlice.name]: commonSlice.reducer,
            [bookingSlice.name]: bookingSlice.reducer,
            [transportSlice.name]: transportSlice.reducer,

            // RTK Query reducers
            [scmServiceApi.reducerPath]: scmServiceApi.reducer,
        },

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([scmServiceApi.middleware]),
    });

export const store = getStore();

// Infer the store's type itself
export type AppStore = typeof store;

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

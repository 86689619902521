import TagList from '@rio-cloud/rio-uikit/TagList';
import { LoadingLocation } from '../../domain/model/location/Location.types';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { getLoadingLocationLabel } from '../../domain/model/location/loadingLocationFormatter';
import React from 'react';

type LocationTagListProps = {
    loadingLocations: LoadingLocation[];
    selectedLoadingLocation: LoadingLocation | undefined;
    selectLoadingLocation: (location: LoadingLocation) => void;
};

export const LocationTagList = ({
    selectedLoadingLocation,
    loadingLocations,
    selectLoadingLocation,
}: LocationTagListProps) => (
    <TagList inline={false} className="padding-15">
        {loadingLocations.map((location: LoadingLocation) => {
            if (location.internalId === selectedLoadingLocation?.internalId) {
                return (
                    <Tag active key={location.internalId}>
                        {getLoadingLocationLabel(location)}
                    </Tag>
                );
            } else {
                return (
                    <Tag
                        onClick={() => selectLoadingLocation(location)}
                        clickable
                        icon="rioglyph-factory"
                        key={location.internalId}
                    >
                        {getLoadingLocationLabel(location)}
                    </Tag>
                );
            }
        })}
    </TagList>
);

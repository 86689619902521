import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { config } from './application/configuration/config';
import { main } from './application/configuration';
import { store } from './application/redux/store';
import { handleLoginRedirect } from './application/configuration/login/redirect';
import { ErrorBoundary } from './application/error/ErrorBoundary';
import { router } from './application/routing/router';
import '../src/application/logging/datadog';
import ErrorPage from './features/ErrorPage';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary fallback={<ErrorPage />}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (import.meta.env.PROD) {
    main(renderApplication);
} else if (import.meta.env.DEV && import.meta.env.MODE === 'local-BE') {
    main(renderApplication);
} else if (import.meta.env.DEV && import.meta.env.MODE === 'development') {
    import('../mocks/serviceMock').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

import { NavLink } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader, { type ModulePropType } from '@rio-cloud/rio-uikit/ApplicationHeader';
import { routes, toBookingView, toSupplierClosingDayView, toTransportView } from '../../application/routing/routes';
import { FormattedMessageUpperFirst } from '../../application/i18n/FormattedMessageUpperFirst';
import {
    isInMaterialPlannerContext,
    isInSupplierContext,
    useSupplierIdRaw,
    useUserRole,
} from '../../application/hooks/selectors';
import MaterialPlannerClosingPeriodsLink from './MaterialPlannerClosingPeriodsLink';
import LoadingLocationManagement from './LoadingLocationManagement';
import SupplierManagement from './SupplierManagement';

type SupplierLinkProps = {
    supplierId: string;
};

const BookingsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={toBookingView(supplierId)}>
        <FormattedMessageUpperFirst id="scm-web.domain.booking" />
    </NavLink>
);

const TransportsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={toTransportView(supplierId)}>
        <FormattedMessageUpperFirst id="scm-web.domain.transport" />
    </NavLink>
);

const SupplierClosingPeriodsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={toSupplierClosingDayView(supplierId)}>
        <FormattedMessageUpperFirst id="scm-web.domain.supplierClosingDays" />
    </NavLink>
);

const SequenceFlowsLink = () => (
    <NavLink to={routes.MATERIAL_PLANNER_SEQUENCE_FLOW_ADMINISTRATION}>
        <FormattedMessageUpperFirst id="scm-web.domain.sequenceFlow" />
    </NavLink>
);

const AppHeader = () => {
    const userRole = useUserRole();
    const supplierId = useSupplierIdRaw();

    const navItems: ModulePropType[] = [];

    if (isInSupplierContext(userRole, supplierId)) {
        navItems.push({ key: `bookings-${supplierId}`, route: <BookingsLink supplierId={supplierId} /> });
        navItems.push({ key: `transports-${supplierId}`, route: <TransportsLink supplierId={supplierId} /> });
        navItems.push({
            key: `supplier-closing-days-${supplierId}`,
            route: <SupplierClosingPeriodsLink supplierId={supplierId} />,
        });
    }

    if (isInMaterialPlannerContext(userRole, supplierId)) {
        navItems.push({ key: 'sequence-flows', route: <SequenceFlowsLink /> });
        navItems.push({ key: 'material-planner-closing-days', route: <MaterialPlannerClosingPeriodsLink /> });
    }

    const supplierOrLoadingLocationManagement =
        userRole === 'supplier' ? <LoadingLocationManagement key="site-selection" /> : <SupplierManagement />;
    const actionBarItems = [
        supplierOrLoadingLocationManagement,
        <DefaultUserMenu key="user-menu" environment={import.meta.env.MODE} />,
    ];

    let homeRoute = '/';

    if (userRole === 'supplier' && !!supplierId) {
        homeRoute = toBookingView(supplierId);
    }

    if (userRole === 'admin' || userRole === 'material-planner') {
        homeRoute = routes.MATERIAL_PLANNER_SEQUENCE_FLOW_ADMINISTRATION;
    }

    return (
        <ApplicationHeader
            homeRoute={<NavLink to={homeRoute} />}
            label={
                <h1 className="text-size-h6 text-size-h4-sm margin-y-5 margin-top-20pct-sm">
                    <FormattedMessageUpperFirst id="scm-web.application.AppHeader.label" />
                </h1>
            }
            navItems={navItems}
            actionBarItems={actionBarItems}
        />
    );
};

export default AppHeader;
